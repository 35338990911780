import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import {
  getFormattedDateFullYearEn,
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { cancelPackageDelivery, ChangeDeliveryStatePackage, getValetById } from '@/src/api/endpoints/packages';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import { getLanguage } from '@/src/utilities/storage';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { useColumns } from '../../packages-valet/useColumns';
import CustomDataTableForValet from './CustomDataTableForValet';
import ImageShow from '@/src/components/image-show/ImageShow';
import { packagesStates, PackageValetType } from '../../types';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';

const InfoValet = ({ close, row, setSnackBarMessage }: any) => {

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [state, setState] = useState(false);

  const { data: valetDetail, isLoading } = useQuery(['valetDetail'], () => getValetById(row?.id), {
    select: (data) => data?.data?.request,
  });

  const packagesWithLastModification = valetDetail?.packages;

  packagesWithLastModification?.forEach((packageValue: PackageValetType) => {
    packageValue.last_modification_date = valetDetail?.last_modification_date;
    packageValue.is_delivery_canceled = valetDetail?.state_reduced?.code === 'Cancelled';
});

  const memoizedResetQueries = useMemo(() => queryClient.resetQueries(['valetDetail']), []);

  const { valetPackagesColumns } = useColumns(packagesWithLastModification);

  const { mutate: mutateCancel } = useMutation(cancelPackageDelivery, {
    onSuccess: () => {
      setSnackBarMessage(t('cancel_valet_delivery_success_message', { ns: 'packages'}));
      close();
    },
    onError: () => {
      setSnackBarMessage(t('cancel_valet_delivery_error_message', { ns: 'packages'}));
      close();
    },
  });

  const cancelValet = () => {
    mutateCancel(valetDetail?.id)
  }

  useEffect(() => {
    memoizedResetQueries;
  }, []);

  const lang = getLanguage()?.split('-')[0];
  return (
    <Box component="div" sx={{ marginY: '5px' }}>
      <Grid container spacing={2}>
        <TitleWithStatus
            title={`${t('info_package_title', { ns: 'packages' })}`}
            statusText={valetDetail?.state_reduced?.name}
            statusBg={packagesStates[valetDetail?.state_reduced?.code]}
            onClose={close}
          />
            <Divider sx={{ mt: '2px', mb: '5px' }} />
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight={600} marginRight="3.5px">
                {t('modal_valet_info_input_creation_date', { ns: 'packages' })}:
              </Typography>
              <Typography variant="subtitle2">
                {getFormattedDateByLanguague(
                  lang,
                  getFormattedDateFullYearEs,
                  getFormattedDateFullYearEn,
                  valetDetail?.creation_date,
                )}
              </Typography>
            </Grid>
            {valetDetail?.delivery_image_url && (
              <Grid item xs={12} sm={6}>
                <InfoLabel first={t('modal_valet_info_input_delivery_image_url', { ns: 'packages' })} second="" />
                  <ImageShow
                    url={valetDetail?.delivery_image_url}
                    open={state}
                    handleOpen={() => setState(true)}
                    handleClose={() => setState(false)}
                  />
              </Grid>
            )}
            <Grid item xs={12} sx={{ pt: '10px' }}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t('modal_valet_info_input_packages_section', { ns: 'packages' })}
              </Typography>
              <Divider sx={{ mt: '2px', mb: '5px' }} />
            </Grid>
            {isLoading ? <CircularSpinner /> : (
              <CustomDataTableForValet columns={valetPackagesColumns} data={packagesWithLastModification ?? []}/>
            )}
            <Grid item xs={12} sx={{ pt: '10px' }}>
              <Typography variant="subtitle1" fontWeight={600}>
                {t('modal_valet_info_input_residency_section', { ns: 'packages' })}
              </Typography>
              <Divider sx={{ mt: '2px', mb: '5px' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight={600} marginRight="3.5px">
                {t('modal_valet_info_input_residency_section_from', { ns: 'packages' })}:
              </Typography>
              <Typography variant="subtitle2">
                {getFormattedDateByLanguague(
                  lang,
                  getFormattedDateFullYearEs,
                  getFormattedDateFullYearEn,
                  valetDetail?.residency?.from,
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight={600} marginRight="3.5px">
                {t('modal_valet_info_input_residency_section_to', { ns: 'packages' })}:
              </Typography>
              <Typography variant="subtitle2">
                {getFormattedDateByLanguague(
                  lang,
                  getFormattedDateFullYearEs,
                  getFormattedDateFullYearEn,
                  valetDetail?.residency?.to,
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_valet_info_input_state', { ns: 'packages' })}
                second={valetDetail?.state_reduced?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_valet_info_input_residency_section_type_name', { ns: 'packages' })}
                second={valetDetail?.residency?.type?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_valet_info_input_residency_section_property_address', { ns: 'packages' })}
                second={valetDetail?.residency?.property?.address}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_valet_info_input_residency_section_property_code', { ns: 'packages' })}
                second={valetDetail?.residency?.property?.code}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_valet_info_input_residency_section_consent', { ns: 'packages' })}
                second={valetDetail?.residency?.package_door_to_door_consent ? t('modal_valet_info_input_residency_section_consent_yes', { ns: 'packages' }) : t('modal_valet_info_input_residency_section_consent_no', { ns: 'packages' })}
                
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('modal_valet_info_total_amount', { ns: 'packages' })}
                second={valetDetail?.total_charged_amount}
              />
            </Grid>
            { valetDetail?.state_reduced?.code === 'Pending' && (
              <>
              <Grid container item xs={12} justifyContent="flex-end">
                <Button
                  variant="contained"
                  size="small"
                  type="button"
                  onClick={cancelValet}
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  {
                    t('modal_valet_info_change_state_cancel', { ns: 'packages' })
                  }
                </Button>
              </Grid>
            </>
          )}
      </Grid>
    </Box>
  );
};

export default InfoValet;
