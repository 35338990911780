import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { createImageFile } from '@/src/api/endpoints/utils';
import { errorHandlerHelper } from '@/src/utilities/helpers/errorHandlerHelper';
import { Camera } from 'react-camera-pro';
import { Padding } from '@mui/icons-material';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

interface ImageUploaderProps {
  htmlFor: string;
  valueName: string;
  values: FormikValues;
  setFieldValue: (field: string, val: string | Array<string>) => void;
  setDisableForm?: (val: boolean) => void;
}

interface CameraRef {
  takePhoto: () => string;
}

const errorMessages = {
  noCameraAccessible: 'No camera accessible',
  permissionDenied: 'Camera permission denied',
  switchCamera: 'Unable to switch camera',
  canvas: 'Canvas not supported',
};

const usePhotoUploader = ({ htmlFor, setFieldValue, values, valueName, setDisableForm }: ImageUploaderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { mutate } = useMutation(createImageFile);
  const [wrongPhotos, setWrongPhotos] = useState<{ code: any; error_messages: any } | null | undefined>(null);
  const [cameraMode, setCameraMode] = useState<'user'|'environment'>('environment');  

  const camera = useRef<CameraRef | null>(null);

  

  const takePhoto = async () => {
    const photo = camera.current?.takePhoto();
    if (photo) {
      setIsLoading(true);
      setWrongPhotos(null);

      // este codigo se encarga de transformar el texto de photo en un objeto valido para el endpoint de subir imagen
      const elementsOfUrl = photo.split(',');
      mutate(
        { image: elementsOfUrl[1], file_name: 'captured_image.jpeg' },
        {
          onSuccess: ({ data }) => {
            Array.isArray(values[`${valueName}`])
              ? setFieldValue(valueName, [...values[`${valueName}`], { image_url: data.image_url }])
              : setFieldValue(valueName, data.image_url);
            setIsLoading(false);
          },
          onError: (err) => {
            setIsLoading(false);
            setWrongPhotos(errorHandlerHelper(err));
          },
        },
      );
    }
  };

  const PhotoUploader = ({ disabled }: { disabled?: boolean }) =>
    isLoading ? (
      <CircularProgress />
    ) : (
      <>  
          <Camera ref={camera} errorMessages={errorMessages} facingMode={cameraMode}/>
          <Grid container  xs={12} >
            <Grid item sx={{  position: 'absolute',bottom: 0,left: '50%',transform: 'translateX(-50%)', display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, padding: '0px 15px'}}>
            <Button
              onClick={() => setCameraMode(cameraMode === 'user' ? 'environment' : 'user')}
              variant="contained"
              sx={{ marginRight: { sm: '10px', xs: '0' }, marginBottom: { sm: '10px', xs: '10px' }, fontSize: '12px', width:'10px' }}
            >
              {<CameraswitchIcon/>}
            </Button>
            <Button
              onClick={takePhoto}
              variant="contained"
              sx={{ marginRight: { sm: '10px', xs: '0' }, marginBottom: { sm: '10px', xs: '10px' }, fontSize: '12px', width:'10px' }}
            >
              {<LinkedCameraIcon />}
            </Button>
            </Grid>
          </Grid>
      </>
    );

  return { PhotoUploader, wrongPhotos };
};

export default usePhotoUploader;
