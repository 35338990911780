import { useTranslation } from 'react-i18next';
import { Visibility } from '@mui/icons-material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import TextLabel from '@/src/components/text-label/TextLabel';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
  getFormattedDateFullYearEn,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { PackageValetType, Valet, packagesStates } from '../types';
import InfoValet from '../components/info-valet/InfoValet';
import ConfirmValet from '../components/confirm-valet/ConfirmValet';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import CheckIcon from '@mui/icons-material/Check';

export const useValetPageColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];

  const { apartments } = useApartmentsFilters();

  const columns: CustomDataTableColumnsProps<Valet>[] = [
    {
      id: 'id',
      sortField: 'id',
      name: t('valet_page_table_column_id', { ns: 'packages' }),
      selector: (row: Valet) => row?.id,
      sortable: true,
      center: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'id',
          query: 'id',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'apartment',
      sortField: 'apartment',
      name: t('valet_page_table_column_apartment', { ns: 'packages' }),
      selector: (row: Valet) => row?.residency?.property?.code,
      sortable: true,
      center: true,
      width: lang === 'en' ? '100px' : '150px',
      filters: apartments,
    },
    // aun no hay un endpoint de estados
    {
      id: 'status',
      sortField: 'status',
      cell: (row: Valet) => (
        <TextLabel
          text={row?.state_reduced?.name?.toLocaleUpperCase()}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={packagesStates[row?.state_reduced?.code]}
        />
      ),
      name: t('valet_page_table_column_status', { ns: 'packages' }),
      selector: (row: Valet) => row?.state_reduced?.name?.toLocaleUpperCase(),
      sortable: true,
      center: true,
      minWidth: '80px',
    },
    {
      id: 'cost',
      sortField: 'cost',
      name: t('valet_page_table_column_cost', { ns: 'packages' }),
      selector: (row: Valet) => row?.total_charged_amount,
      sortable: true,
      center: true,
      maxWidth: '1%',
    },
    {
      id: 'delivery_date_time',
      name: t('valet_page_table_column_delivery_date', { ns: 'packages' }),
      selector: (row: Valet) =>
        row?.residency?.to
          ? getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row?.residency?.to)
          : '-',
      sortField: 'delivery_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'delivery-date',
          query: 'DeliveryDate',
          type: 'date',
        },
      ],
    },
  ];

  const headerActions: ActionHeader[] = [];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <Visibility sx={props.sx} />,
      customizedTitle: true,
      component: (props: any) => <InfoValet {...props} />,
      width: { xs: '96%', sm: '60%' },
    },
    {
      id: 'confirm',
      icon: (props: any) => <CheckIcon sx={props.sx} />,
      customizedTitle: true,
      component: (props: any) => <ConfirmValet {...props} />,
      isHidden: (row: Valet) => row?.state_reduced?.code !== 'Pending',
      width: { xs: '50%', sm: '30%' },
    },
  ];

  return {
    columns,
    headerActions,
    actionColumns,
  };
};
