import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useValetPageColumns } from './useValetPageColumns';

export const ValetPage = () => {
  const { t } = useTranslation();
  const { columns, actionColumns, headerActions } = useValetPageColumns();

  return (
    <CustomDataTable
      title={t('packages_valet_page_title', { ns: 'packages' })}
      columns={columns}
      resource="requests"
      pathApi="packaging/v1/door-to-door/requests"
      listName="requests"
      extraActionsInHeader={headerActions}
      canSearch
      canExport
      defaultSortFieldId="id"
      defaultSortAsc={false}
      customActionColumns={actionColumns}
    />
  );
};
