import { useTranslation } from 'react-i18next';
import { Visibility } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import { getLanguage } from '@/src/utilities/storage';
import { ReportsTypes } from '../types';
import CreatePackageTypeFormContainer from '../components/create-package-type-form/CreatePackageTypeForm.container';
import InfoPackageType from '../components/info-package-type/PackageTypeDetailForm.container';
import { ReportDetailsPage } from './report-details/ReportDetailPage';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];

  const columns: CustomDataTableColumnsProps<ReportsTypes>[] = [
    {
      id: 'unit',
      sortField: 'unit',
      name: t('reports_page_table_column_unit', { ns: 'packages' }),
      selector: (row: ReportsTypes) => row?.property?.code,
      sortable: true,
      center: true,
    },
    {
      id: 'package_delivered_count',
      sortField: 'package_delivered_count',
      name: t('reports_page_table_column_package_delivered_count', { ns: 'packages' }),
      selector: (row: ReportsTypes) => row.package_delivered_count,
      sortable: true,
      center: true,
    },
    {
      id: 'door_to_door_package_delivered_count',
      sortField: 'door_to_door_package_delivered_count',
      name: t('reports_page_table_column_door_to_door_package_delivered_count', { ns: 'packages' }),
      selector: (row: ReportsTypes) => row.door_to_door_package_delivered_count,
      sortable: true,
      center: true,
    },
    {
      id: 'door_to_door_fee_amount',
      sortField: 'door_to_door_fee_amount',
      name: t('reports_page_table_column_door_to_door_fee_amount', { ns: 'packages' }),
      selector: (row: ReportsTypes) => `$${row.door_to_door_fee_amount}`,
      sortable: true,
      center: true,
    },
    {
      id: 'storage_fee_amount',
      sortField: 'storage_fee_amount',
      name: t('reports_page_table_column_storage_fee_amount', { ns: 'packages' }),
      selector: (row: ReportsTypes) => `$${row.storage_fee_amount}`,
      sortable: true,
      center: true,
    },
    {
      id: 'total_fee_amount',
      sortField: 'total_fee_amount',
      name: t('reports_page_table_column_total_fee_amount', { ns: 'packages' }),
      selector: (row: ReportsTypes) => `$${row.total_fee_amount}`,
      sortable: true,
      center: true,
    },

  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'packages-type-create',
      component: () => (
        <CustomTableAction
          modalTitle="package_types"
          component={(props: any) => <CreatePackageTypeFormContainer {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', sm: '530px' }}
        />
      ),
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <Visibility sx={props.sx} />,
      customizedTitle: true,
      component: (props: any) => <ReportDetailsPage {...props} />,
      width: { xs: '96%', sm: '900px' },
    }
  ];

  return {
    columns,
    headerActions,
    actionColumns,
  };
};
