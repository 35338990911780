import { Autocomplete, Box, CardMedia, Checkbox, Grid, IconButton, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CustomInput from '@/src/components/forms/CustomInput';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import CustomTextArea from '@/src/components/forms/text-area';
import { CustomDatePicker } from '@/src/components/date-picker/DatePicker';
import PdfFileUploader from '@/src/components/pdf-file-uploader/PdfFileUploader';
import { PetFormProps } from '../types';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { handlerDateSelector } from '@/src/utilities/helpers/utils';

const PetsForm = ({
  handleSubmit,
  values,
  setFieldValue,
  errors,
  loading,
  errorMessage,
  isSubmitLoading,
  sexes,
  types,
  breeds,
  t,
  setSnackBarMessage,
  AttachedImagesUploader,
  isEditForm,
  deleteImage,
}: PetFormProps) => (
  <Box component="form" onSubmit={handleSubmit} sx={{ marginY: '5px' }}>
    {loading ? (
      <CircularSpinner />
    ) : (
      <Grid container spacing={2} pt={1}>
        <Grid item xs={12} sm={6}>
          <CustomInput
            field="name"
            errors={errors}
            label={t('pet_form_name_field_label', { ns: 'residents' })}
            placeholder={t('pet_form_name_field_placeholder', { ns: 'residents' })}
            setFieldValue={setFieldValue}
            values={values}
            autoFocus
            maxLength={50}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disabled={isEditForm}
            id="type"
            size="small"
            value={values?.type}
            isOptionEqualToValue={(option, value) => option.name === value.name || value === ''}
            getOptionLabel={(option) => option?.name ?? ''}
            options={types ?? []}
            onChange={(e, value) => setFieldValue('type', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`${t('pet_form_type_field_label', { ns: 'residents' })} * `}
                placeholder={t('pet_form_type_field_placeholder', { ns: 'residents' })}
                error={errors.type !== undefined}
                helperText={errors.tpe?.toString()}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disabled={!values?.type?.id}
            id="breed"
            size="small"
            value={values?.breed}
            isOptionEqualToValue={(option, value) => option.name === value.name || value === ''}
            getOptionLabel={(option) => option?.name ?? ''}
            options={breeds ?? []}
            onChange={(e, value) => setFieldValue('breed', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`${t('pet_form_breed_field_label', { ns: 'residents' })} * `}
                placeholder={t('pet_form_breed_field_placeholder', { ns: 'residents' })}
                error={errors.breed !== undefined}
                helperText={errors.breed?.toString()}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomDatePicker
              value={values.birthday}
              onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'birthday')}
              maxDate={new Date().getTime()}
              fieldValue="birthday"
              placeholder={t('pet_form_birthday_field_placeholder', { ns: 'residents' })}
              errorMessage={errors.birthday}
              required
            />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="sex"
            size="small"
            value={values?.sex}
            getOptionLabel={(option) => option?.name ?? ''}
            options={sexes ?? []}
            onChange={(e, value) => setFieldValue('sex', value)}
            isOptionEqualToValue={(option, value) => option.name === value.name || value === ''}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`${t('pet_form_sex_field_label', { ns: 'residents' })} * `}
                placeholder={t('pet_form_sex_field_placeholder', { ns: 'residents' })}
                error={errors.sex !== undefined}
                helperText={errors.sex?.toString()}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomDatePicker
            value={values.lastVaccinationDate}
            onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'lastVaccinationDate')}
            maxDate={new Date().getTime()}
            fieldValue="lastVaccinationDate"
            placeholder={t('resident_pet_vaccination_date_placeholder', { ns: 'residents' })}
            errorMessage={errors.lastVaccinationDate}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextArea
            id="comments"
            placeholder={t('pets_form_comments_field_placeholder', { ns: 'residents' })}
            minRows={3}
            value={values.comments}
            onChange={(e) => {
              setFieldValue('comments', e.target.value);
            }}
            maxLength={500}
          />
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle2">{t('service_animal_file', { ns: 'residents' })}</Typography>
          <PdfFileUploader
            displayTitle={false}
            values={values}
            setFieldValue={setFieldValue}
            setError={(v: string, message: string) => message && setSnackBarMessage(message, 'error')}
          />
        </Grid>
        <Grid item xs={12} sm={3} display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="subtitle2">{t('image_url', { ns: 'residents' })}</Typography>
          {values.imageUrl ? (
            <Grid item display="flex">
              <Box
                component="div"
                sx={{
                  borderRadius: '20px',
                  position: 'relative',
                }}
              >
                <IconButton
                  onClick={() => deleteImage()}
                  sx={{
                    position: 'absolute',
                    top: -15,
                    right: -26,
                  }}
                >
                  <CancelRoundedIcon />
                </IconButton>
                <CardMedia
                  component="img"
                  height={130}
                  image={values.imageUrl}
                  alt="images"
                  sx={{ borderRadius: '10px' }}
                />
              </Box>
            </Grid>
          ) : (
            <AttachedImagesUploader />
          )}
          <ErrorText error={errors.imageUrl as string} />
        </Grid>
        <Grid item xs={12} sm={3} justifyContent="center">
          <Typography variant="subtitle2">{t('is_service_animal')}</Typography>
          <Checkbox
            onChange={(e, checked) => setFieldValue('isServiceAnimal', checked)}
            checked={values.isServiceAnimal}
          />
        </Grid>
        <Grid item xs={12} sm={3} justifyContent="center">
          <Typography variant="subtitle2">{t('is_sterilized')}</Typography>
          <Checkbox onChange={(e, checked) => setFieldValue('isSterilized', checked)} checked={values.isSterilized} />
        </Grid>
        {errorMessage && (
          <Grid item xs={12} sm={8} md={6}>
            <ErrorHelper error={errorMessage} />
          </Grid>
        )}
        <Grid container item xs={12} justifyContent="flex-end">
          <LoadingButton
            loading={isSubmitLoading}
            variant="contained"
            size="small"
            type="submit"
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            {t('form_button_submit_text', { ns: 'users' })}
          </LoadingButton>
        </Grid>
      </Grid>
    )}
  </Box>
);

export default PetsForm;
