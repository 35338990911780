import { Box, Button, Divider, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ChangeDeliveryStatePackage, getValetById } from '@/src/api/endpoints/packages';
import { getLanguage } from '@/src/utilities/storage';
import { TLanguagesOptions } from '@/src/utilities/helpers/commonTypes';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import usePhotoUploader from '@/src/components/photo-uploader/usePhotoUploader';
import { LoadingButton } from '@mui/lab';
import useImageUploader from '@/src/components/image-uploader/useImageUploader';

const getInitialValues = () => ({
  valetImage: '',
});

export const validate = (t: (par1: string, par2?: TLanguagesOptions) => string, code: string) =>
  Yup.object().shape({
    valetImage: Yup.string().when([], {
      is: () => code === 'Pending',
      then: (schema) => schema.required(t('required_field', { ns: 'errors' })),
      otherwise: (schema) => schema,
    })
    .test('not-empty', t('valetImage_empty', { ns: 'errors' }), (value) => value !== ''),
  });

const InfoValet = ({ close, row, setSnackBarMessage }: any) => {
  const { t } = useTranslation();
  const [showCamara, setShowCamara ] = useState<boolean | null>(null);

  const queryClient = useQueryClient();

  const { data: valetDetail, isLoading } = useQuery(['valetDetail'], () => getValetById(row?.id), {
    select: (data) => data?.data?.request,
  });

  useEffect(() => {
    queryClient.resetQueries(['valetDetail']);
  }, [queryClient]);

  const { mutate: mutateChangeState } = useMutation(ChangeDeliveryStatePackage, {
    onSuccess: () => {
      setSnackBarMessage(t('delivered_valet_success_message', { ns: 'packages' }));
    },
    onError: () => {
      setSnackBarMessage(t('delivered_valet_error_message', { ns: 'packages' }));
    },
  });

  const onSubmit = (data: FormikValues) => {
    const changeStateRequest = { id: valetDetail?.id, valetImage: data?.valetImage };
    mutateChangeState(changeStateRequest);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate(t, valetDetail?.state?.code),
  });

  const deleteImage = (value: keyof typeof values) => {
    setFieldValue(value, '');
  };

  const { PhotoUploader, wrongPhotos: wrongCoverPhotos } = usePhotoUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'valetImage',
    setFieldValue,
  });

  const lang = getLanguage()?.split('-')[0];

  const { ImageUploader, wrongImages: wrongCoverImages } = useImageUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'valetImage',
    setFieldValue,
  });

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ marginY: '5px', backgroundColor: 'white' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ pt: '0 !important' }}>
          <Typography variant="subtitle1" fontWeight={600}>
            {t('confirm_package_sub_title', { ns: 'packages' })}
          </Typography>
          <Divider sx={{ mt: '2px', mb: '5px' }} />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography component="span" variant="subtitle2">
            {t('attached_delivered_image', { ns: 'packages' })}
          </Typography>
        </Grid>
        {
          showCamara === null ? (
              <Grid container item xs={12} justifyContent="flex-end" sx={{ display: 'flex', flexDirection:{ lg: 'row', xs: 'column-reverse'} }}>
                <Button
                  variant="outlined"
                  size="small"
                  type="button"
                  onClick={() => { close()}}
                  sx={{ marginRight: {lg: '20px', md: '0px'}}}
                >
                  {t('modal_valet_info_cancel', { ns: 'packages' })}
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  type="button"
                  onClick={() => setShowCamara(false)}
                  sx={{ marginRight: {lg: '20px', md: '0px'}, marginBottom: {lg: '0px', xs: '10px'} }}
                >
                  {t('modal_valet_info_use_gallery', { ns: 'packages' })}
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  type="button"
                  onClick={() => setShowCamara(true)}
                  sx={{ marginBottom: {lg: '0px', xs: '10px'} }}
                >
                  {t('modal_valet_info_use_camera', { ns: 'packages' })}
                </Button>
              </Grid>
          ) : (
            <>
              {!showCamara ? (
                <>
                  <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', m: '0px 15px' }}>
                      { values.valetImage === '' ? (
                        <>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {t('upload_image', { ns: 'packages' })}
                      </Typography>
                      <ImageUploader />
                      </>
                      ) : (
                        <Grid item xs={8} sm={8} md={8} sx={{ position: 'relative', m: '0px 15px', height: '250px' }}>
                          <Tooltip title={t('remove_item_tooltip_msn', { ns: 'packages' })}>
                            <IconButton onClick={() => deleteImage('valetImage')} sx={{ position: 'absolute', right: -15, top: -14 }}>
                              <CancelRoundedIcon />
                            </IconButton>
                          </Tooltip>
                          <CustomCardMedia src={values?.valetImage} alt="cover-image" height="250px" />
                        </Grid>
                      )}
                      <ErrorText error={wrongCoverImages?.code} />
                    </Grid>
                  <Grid container item xs={12} justifyContent="flex-end" sx={{ display: 'flex', flexDirection:{ lg: 'row', xs: 'column-reverse'} }}>
                    <Button
                      variant="outlined"
                      size="small"
                      type="button"
                      sx={{ marginRight: {lg: '20px', md: '0px'}, marginBottom: {lg: '0px', xs: '10px'} }}
                      onClick={() => { close()}}
                    >
                      {t('modal_valet_info_cancel', { ns: 'packages' })}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      type="button"
                      sx={{ marginRight: {lg: '20px', md: '0px'}, marginBottom: {lg: '0px', xs: '10px'} }}
                      onClick={() => {
                        setShowCamara(null);
                        deleteImage('valetImage');
                      }}
                    >
                      {t('modal_valet_info_go_back', { ns: 'packages' })}
                    </Button>
                    <LoadingButton
                      size="small"
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={!values?.valetImage}
                      
                    >
                      {t('modal_valet_info_change_state_confirm', { ns: 'packages' })}
                    </LoadingButton>
                  </Grid>
                </>
              ) : (
                <>
                <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative', marginLeft: '15px', height: '250px' }}>
                { values.valetImage === '' ? (
                    <>
                      <PhotoUploader />
                      <ErrorText error={wrongCoverPhotos?.code} />
                      {errors?.valetImage && <ErrorText error={t('attach_delivered_image', { ns: 'packages' })} />}
                    </>
                  ) : (
                    <>
                      <Grid item xs={8} sm={8} md={8} sx={{ position: 'relative', m: '0px 15px', height: '250px' }}>
                        <Tooltip title={t('remove_item_tooltip_msn', { ns: 'packages' })}>
                          <IconButton onClick={() => deleteImage('valetImage')} sx={{ position: 'absolute', right: -15, top: -14 }}>
                            <CancelRoundedIcon />
                          </IconButton>
                        </Tooltip>
                        <CustomCardMedia src={values?.valetImage} alt="cover-image" height="250px" />
                      </Grid>
                    </>
                  )}
                  </Grid>
                  <Grid container item xs={12} justifyContent="flex-end" sx={{ display: 'flex', flexDirection:{ lg: 'row', xs: 'column-reverse'} }}>
                    <Button
                      variant="outlined"
                      size="small"
                      type="button"
                      sx={{ marginRight: {lg: '20px', md: '0px'}, marginBottom: {lg: '0px', xs: '10px'} }}
                      onClick={() => { close()}}
                      >
                      {t('modal_valet_info_cancel', { ns: 'packages' })}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      type="button"
                      sx={{ marginRight: {lg: '20px', md: '0px'}, marginBottom: {lg: '0px', xs: '10px'} }}
                      onClick={() => {
                        setShowCamara(null);
                        deleteImage('valetImage');
                      }}
                    >
                      {t('modal_valet_info_go_back', { ns: 'packages' })}
                    </Button>
                    <LoadingButton
                      size="small"
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={!values?.valetImage}
                    >
                      {t('modal_valet_info_change_state_confirm', { ns: 'packages' })}
                    </LoadingButton>
                  </Grid>
                </>
              )}
            </>
          )
        }
      </Grid>
    </Box>
  );
};

export default InfoValet;
