import { Box, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useBreakpoints from '@/src/hooks/common/useBreakpoints';
import { FadeIn } from '../animations';
import CustomLoader from '../custom-loader';
import RefreshButton from '../refresh-button/RefreshButton';
import BackToBaseButton from './components/BackToBaseButton';
import CanDisable from './components/CanDisable';
import CanExport from './components/CanExport';
import CustomTableAction from './components/CustomTableAction';
import CanDelete from './components/delete/CanDelete';
import DisableFilter from './components/DisableFilter';
import Search from './components/Search';
import { TableHeader } from './components/TableHeader.styled';
import DataTableStyled, { CustomDataTableCardContainer, CustomRowHeader } from './CustomDataTable.styled';
import { CustomDataTableContext } from './CustomDataTableContext';
import useCustomFetch from './hooks/useCustomFetch';
import './style.css';
import { ActionColumn, ActionHeader, QueryFilterItem } from './types/DataTableFormModal';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';
import useSnackBar from './useSnackBar';
import CanFilter from './components/can-filter';
import FilterColumnIcon from './components/filter-column-icon';
import NoDataComponent from './components/NoDataComponent';
import TableHeaderButton from '../table-header-button/TableHeaderButton';

interface CustomDataTableProps {
  columns: Array<any>;
  title: string;
  resource: string;
  canDelete?: boolean;
  canDisable?: boolean;
  canExport?: boolean;
  exportApi?: string;
  canSearch?: boolean;
  fake?: boolean;
  extraFilters?: Array<QueryProps>;
  extraActionsInHeader?: ActionHeader[];
  filterComponentsList?: QueryFilterItem[];
  defaultSortFieldId?: string;
  defaultSortAsc?: boolean;
  listName: string;
  pathApi: string;
  customActionColumns?: ActionColumn[];
  backToBaseGrid?: () => void;
  exportName?: string;
  clientSidePagination?: boolean;
  customPageSize?: number;
}

export const CustomDataTableComponent = ({
  columns,
  title,
  resource,
  canDisable = false,
  canDelete,
  canExport,
  exportApi,
  canSearch,
  fake = false,
  extraFilters,
  extraActionsInHeader,
  filterComponentsList,
  defaultSortFieldId,
  defaultSortAsc,
  listName,
  pathApi,
  customActionColumns,
  backToBaseGrid,
  exportName,
  clientSidePagination = false,
  customPageSize,
}: CustomDataTableProps) => {
  const formattedResource = resource.replaceAll('-', '_');

  const {
    data,
    loading,
    totalRows,
    pageSize,
    handlePageChange,
    handleSort,
    setSearchText,
    setNeedRefresh,
    setStartRefresh,
    filterColumns,
    orderDescending,
    orderField,
    filterString,
    setFilterString,
  } = useCustomFetch(
    pathApi,
    listName,
    canDisable,
    !!filterComponentsList,
    clientSidePagination,
    columns,
    fake,
    extraFilters,
    defaultSortFieldId,
    defaultSortAsc,
    customPageSize,
  );
  const { t } = useTranslation();
  const { SnackBar } = useSnackBar();
  const { matchesSm } = useBreakpoints();
  const { showDisabled, setShowDisabled } = useContext(CustomDataTableContext);
  const handleSearchChange = async (e: any) => {
    setTimeout(() => {
      setSearchText(e.target.value);
    }, 1000);
  };

  const extraColumnsActions = React.useCallback(
    (row: any) => (
      <Stack direction="row" spacing={2}>
        {customActionColumns?.map((customActionColumn: ActionColumn) => {
          if (customActionColumn.isHidden && customActionColumn.isHidden(row)) return;
          if (customActionColumn.onIconClick) {
            return (
              <Tooltip
                key={customActionColumn.id}
                title={t(customActionColumn.id.replace('-', '_'), { ns: 'cdtModal' })}
              >
                <Box onClick={() => customActionColumn.onIconClick(row)}>
                  {customActionColumn.icon({ sx: { color: 'secondary.main', cursor: 'pointer' }, row })}
                </Box>
              </Tooltip>
            );
          }
          return (
            <CustomTableAction
              key={customActionColumn.id}
              row={row}
              component={customActionColumn.component}
              Icon={customActionColumn.icon}
              width={customActionColumn.width}
              modalTitle={resource}
              type={`${customActionColumn.id}`}
              customizedTitle={customActionColumn.customizedTitle}
            />
          );
        })}
        {canDisable && <CanDisable id={row.id} resource={formattedResource} isDisabled={row.is_disabled} />}
        {canDelete && <CanDelete id={row.id} resource={formattedResource} fake={fake} pathApi={pathApi} />}
      </Stack>
    ),
    [],
  );
  let finalColumns = filterColumns.map((item) => {
    const { name, sortable, filters, sortField, center, right } = item;

    if (!sortable && !filters)
      return {
        ...item,
        center,
        right,
        name: (
          // eslint-disable-next-line no-nested-ternary
          <CustomRowHeader align={center ? 'center' : right ? 'flex-end' : 'flex-start'}>{name}</CustomRowHeader>
        ),
      };

    const col = {
      ...item,
      name: (
        <FilterColumnIcon
          orderField={orderField}
          orderDescending={orderDescending}
          handleSort={handleSort}
          name={name}
          filters={filters}
          sortable={sortable}
          sortField={sortField}
          center={center}
          right={right}
        />
      ),
      sortable: false,
    };
    return col;
  });
  if (canDisable || canDelete || (customActionColumns && customActionColumns?.length > 0))
    finalColumns = finalColumns.concat([
      {
        cell: extraColumnsActions,
        name: <CustomRowHeader>{t('actions')}</CustomRowHeader>,
        center: true,
        maxWidth: '200px',
      },
    ]);

  const tableHeader = useMemo(
    () => (
      <TableHeader
        left={
          <>
            <div className="flex">
              {backToBaseGrid && <BackToBaseButton disabled={loading} backToBase={backToBaseGrid} />}
              <Typography sx={{ fontSize: '26px', fontWeight: 400, alignSelf: 'center' }}>{title}</Typography>
            </div>

            {matchesSm ? (
              <div className="flex">
                <RefreshButton refresh={setNeedRefresh} disabled={loading} />
                {extraFilters && filterComponentsList && (
                  <Box sx={{ display: `flex`, justifyContent: 'center', alignItems: 'end' }}>
                    <CanFilter
                      filterComponentsList={filterComponentsList}
                      setStartRefresh={setStartRefresh}
                      pageLoading={loading}
                    />
                  </Box>
                )}
                {canExport && (
                  <CanExport
                    pageLoading={loading}
                    resource={exportName || formattedResource}
                    columns={columns}
                    pathApi={exportApi || pathApi}
                    fake={fake}
                    extraFilters={extraFilters}
                    customApi={exportApi !== undefined}
                  />
                )}
              </div>
            ) : (
              <RefreshButton refresh={setNeedRefresh} disabled={loading} />
            )}
          </>
        }
        right={
          <>
            {filterString && <TableHeaderButton text={t('clear_filters')} handleClick={() => setFilterString('')} />}
            {canSearch && <Search handleSearchChange={handleSearchChange} disabled={loading} />}
            {canDisable && (
              <DisableFilter
                resource={formattedResource}
                setShowDisabled={setShowDisabled}
                isLoading={loading}
                disabled={showDisabled}
              />
            )}
            {loading && extraActionsInHeader ? (
              <Box sx={{ display: `${matchesSm ? 'none' : 'flex'}`, justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color="primary" size="1rem" />
              </Box>
            ) : (
              extraActionsInHeader?.map((item: ActionHeader) => <item.component key={item.id} />)
            )}
            {extraFilters && filterComponentsList && !matchesSm && (
              <Box sx={{ display: `${matchesSm ? 'none' : 'flex'}`, justifyContent: 'center', alignItems: 'end' }}>
                <CanFilter
                  filterComponentsList={filterComponentsList}
                  setStartRefresh={setStartRefresh}
                  pageLoading={loading}
                />
              </Box>
            )}
            {canExport && !matchesSm && (
              <CanExport
                pageLoading={loading}
                resource={exportName || formattedResource}
                columns={columns}
                pathApi={exportApi || pathApi}
                fake={fake}
                extraFilters={extraFilters}
                customApi={exportApi !== undefined}
              />
            )}
          </>
        }
      />
    ),
    [t, loading, filterComponentsList],
  );

  return (
    <Box>
      <CustomDataTableCardContainer>
        <FadeIn>{tableHeader}</FadeIn>
        <FadeIn>
          <DataTableStyled
            dense
            className="react_dataTable"
            data={data}
            columns={finalColumns}
            noDataComponent={<NoDataComponent finalColumns={finalColumns} t={t} />}
            title={title}
            progressPending={loading}
            progressComponent={<CustomLoader />}
            pagination
            paginationServer={!clientSidePagination}
            paginationPerPage={pageSize}
            paginationTotalRows={totalRows}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={!clientSidePagination ? handlePageChange : () => {}}
            sortServer={!clientSidePagination}
            noHeader
            onSort={!clientSidePagination ? handleSort : () => {}}
            defaultSortFieldId={defaultSortFieldId}
            defaultSortAsc={defaultSortAsc}
            theme="palierGridTheme"
          />
        </FadeIn>
      </CustomDataTableCardContainer>
      <SnackBar />
    </Box>
  );
};

const CustomDataTable = ({ ...props }: CustomDataTableProps) => (
  // <CustomDataTableProvider>
  <CustomDataTableComponent {...props} />
  // </CustomDataTableProvider>
);

CustomDataTableComponent.defaultProps = {
  canDelete: false,
  canDisable: false,
  canExport: false,
  canSearch: false,
  extraActionsInHeader: null,
  fake: false,
};
CustomDataTable.defaultProps = {
  canDelete: false,
  canDisable: false,
  canExport: false,
  canSearch: false,
  extraActionsInHeader: null,
  fake: false,
};

export default CustomDataTable;
