import { Button, Card} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { CustomDatePicker } from '@/src/components/date-picker/DatePicker';
import { useFilters, today } from './useFilters';
import { FormBox, CustomBox } from './Filter.styled';
import { handlerDateSelector } from '@/src/utilities/helpers/utils';
import { FilterProps } from './types';

const Filters = (props: FilterProps) => {
  const { handleSubmit, values, setFieldValue, errors, placeholder_from, placeholder_to, handleChange } =
    useFilters(props);

  const matches = useMediaQuery('(min-width:900px)');

  const { t } = useTranslation();

  return (
    <Card style={{ margin: '0 auto', marginBottom: '10px' }}>
      <FormBox onSubmit={handleSubmit}>
        <CustomBox component="div">
          <CustomDatePicker
            value={values.from}
            width={`${matches ? '50%' : '70%'}`}
            minWidth="115px"
            onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'from')}
            fieldValue="from"
            isReadOnly={false}
            placeholder={placeholder_from}
            errorMessage={errors.from as string}
          />
          <CustomDatePicker
            value={values.to}
            width={`${matches ? '50%' : '70%'}`}
            minWidth="115px"
            onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'to')}
            fieldValue="to"
            isReadOnly={false}
            placeholder={placeholder_to}
            errorMessage={errors.from as string}
          />
          <Button sx={{ height: '40px' }} color="primary" variant="outlined" type="submit" size="small">
            {t('search')}
          </Button>
        </CustomBox>
      </FormBox>
    </Card>
  );
};

export default Filters;
