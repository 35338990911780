import { FormEvent } from 'react';
import { FormikErrors, FormikValues } from 'formik';
import { grey, orange, red } from '@mui/material/colors';
import { DataTableFormModal } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { PropertyType } from '../residents/residents-section/types';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

export type TPackagesStateOptions = {
  [key: string]: string;
};

export const packagesStates: TPackagesStateOptions = {
  Pending: '#516F3F',
  Delivered: '#424867',
  PendingToStore: '#CA8A04',
  Cancelled: red.A400,
  Valet: orange.A400,
  Expired: red.A400,
};

export interface ReportPackageDetailTypes {
  package_type: { id: string; name: string };
  package_delivered_count: number;
  door_to_door_package_delivered_count: number;
  door_to_door_fee_amount: number;
  storage_fee_amount: number;
}

export interface ReportDetailsTypes extends ReportsTypes {
  type_reports: Array<ReportPackageDetailTypes>;
}

export interface ReportsTypes {
  property: { code: string; address: string };
  package_delivered_count: number;
  door_to_door_package_delivered_count: number;
  door_to_door_fee_amount: number;
  storage_fee_amount: number;
  total_fee_amount: number;
}

export interface packagesTypes {
  id: number;
  name: string;
  price: number;
}

export interface Package {
  internal_code: number;
  package_code: string;
  property: {
    code: string;
  };
  package_state: {
    code: string;
    name: string;
  };
  resident: {
    name: string;
    surname: string;
    type: string;
  };
  is_expirable: boolean;
  location: string;
  creation_date: number;
  delivery_date: number | null;
  delivered_to: string | null;
}

export interface Valet {
  id: number,
  creation_date: number,
  package_count: number,
  residency: {
      id: number,
      from: number | null,
      to: number | null,
      type: string | null,
      property: {
          id: number,
          code: string,
          address: string
      },
      package_door_to_door_consent: boolean
  },
  state_reduced: {
      code: string,
      name: string
  }
  total_charged_amount: number;
}

export interface PackageDetail {
  carrier: {
    id: number;
    name: string;
  };
  creation_date: number;
  creation_user: string;
  delivered_to: string | null;
  delivery_code: string | null;
  delivery_date: number | null;
  internal_code: number;
  package_code: string;
  location: string;
  package_state: {
    code: string;
    name: string;
  };
  property: {
    code: string;
  };
}

export interface ValetDetail {
  id: 2,
  creation_date: number,
  total_charged_amount: number,
  delivery_image_url: string | null,
  state: {
      code: string,
      name: string
  },
  residency: {
      id: number,
      from: number | null,
      to: number | null,
      type: {
          code: string,
          name: string
      },
      property: {
          id: number,
          code: string,
          address: string
      },
      package_door_to_door_consent: boolean
  },
  packages: PackageValetType[]
}

export interface PackageValetType {
  internal_code: number,
  price: number,
  delivery_date: number | null,
  delivered_to: number | null,
  creation_date: number | null,
  last_modification_date: number | null,
  is_delivery_canceled: boolean,
  type: {
    code: string,
    name: string,
  },
  carrier: {
      name: string,
      code: string
  },
  is_expirable: boolean
}

export interface Carrier {
  id: number;
  name: string;
  code: string;
}

export interface DeletePackageProps {
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
  row: Package;
}

export interface PackageLabelProps {
  row: Package | undefined | null;
}

export interface LoadPackageModalProps extends DataTableFormModal {
  setCurrentStep: (step: number) => void;
  currentStep: number;
  propertiesList?: Array<PropertyType>;
}

export interface DeliveryPackageFormProps {
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  errors: FormikErrors<FormikValues>;
  isLoadingSubmit: boolean;
  sendPackageDelivery: ({ userId, packages }: { userId: string; packages: Array<Carrier> }) => void;
  closeModal: () => void;
  handleToggle: (code: number) => void;
  handleToggleAll: () => void;
  detail: any;
  isLoading: boolean;
  number: any;
  searchDone: boolean;
}
export interface Carrier {
  internal_code: number;
  carrier: string;
  status?: boolean;
}
export interface Package {
  id: number;
  description: string;
}

export interface DetailDeliveryHook {
  userId: string;
  name?: string;
  surname?: string;
  avatarUrl?: string;
  packages: Array<Carrier>;
  error?: string;
}
export interface DetailLoadTrackHook {
  userId: string;
  name?: string;
  surname?: string;
  avatarUrl?: string;
  packages: Array<Carrier>;
  error?: string;
}

export interface PrintLabelProps {
  close: () => void;
  setSnackBarMessage: (message: string, sever?: string) => void;
  row: Package;
}

export interface DataTableForValetProps {
  title?: string;
  data: PackageValetType[];
  columns: CustomDataTableColumnsProps<PackageValetType>[];
}