import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './router';
import AppTheme from './utilities/theme/AppTheme';
import AuthContextProvider from './context/auth.context';
import getQueryClient from './utilities/helpers/getQueryClient';
import UserContextProvider from './context/user.context';
import FirebaseContextProvider from './context/firebase.context';
import useSnackBar from './components/custom-snackbar/useSnackBar';
import { CustomDataTableProvider } from './components/custom-data-table/CustomDataTableContext';

const App = () => {
  const { SnackBar, setSnackBarMessage } = useSnackBar();

  return (
    <QueryClientProvider client={getQueryClient()}>
      <BrowserRouter>
        <AuthContextProvider>
          <FirebaseContextProvider setSnackBarMessage={setSnackBarMessage}>
              <AppTheme>
                <UserContextProvider>
                  <CustomDataTableProvider>
                    {/** TODO: Se debe buscar sacar este context de aca y devolverlo a la custom data table */}
                    <AppRouter />
                    <SnackBar />
                  </CustomDataTableProvider>
                </UserContextProvider>
              </AppTheme>
          </FirebaseContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
