import { useTranslation } from 'react-i18next';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getLanguage } from '@/src/utilities/storage';
import { ReportPackageDetailTypes } from '../../types';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];

  const columns: CustomDataTableColumnsProps<ReportPackageDetailTypes>[] = [
    {
      id: 'package_type',
      sortField: 'package_type',
      name: t('reports_detail_page_table_column_package_type', { ns: 'packages' }),
      selector: (row: ReportPackageDetailTypes) => row?.package_type?.name,
      sortable: true,
      center: true,
    },
    {
      id: 'package_delivered_count',
      sortField: 'package_delivered_count',
      name: t('reports_detail_page_table_column_package_delivered_count', { ns: 'packages' }),
      selector: (row: ReportPackageDetailTypes) => row.package_delivered_count,
      sortable: true,
      center: true,
    },
    {
      id: 'door_to_door_package_delivered_count',
      sortField: 'door_to_door_package_delivered_count',
      name: t('reports_detail_page_table_column_door_to_door_package_delivered_count', { ns: 'packages' }),
      selector: (row: ReportPackageDetailTypes) => row.door_to_door_package_delivered_count,
      sortable: true,
      center: true,
    },
    {
      id: 'door_to_door_fee_amount',
      sortField: 'door_to_door_fee_amount',
      name: t('reports_detail_page_table_column_door_to_door_fee_amount', { ns: 'packages' }),
      selector: (row: ReportPackageDetailTypes) => `$${row.door_to_door_fee_amount}`,
      sortable: true,
      center: true,
    },
    {
      id: 'storage_fee_amount',
      sortField: 'storage_fee_amount',
      name: t('reports_detail_page_table_column_storage_fee_amount', { ns: 'packages' }),
      selector: (row: ReportPackageDetailTypes) => `$${row.storage_fee_amount}`,
      sortable: true,
      center: true,
    },
  ];

  return {
    columns,
  };
};
