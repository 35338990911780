import { useFormik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';
import { FilterProps } from './types';

export const today = new Date().getTime();

export const useFilters = ({ showTable, setShowTable, setExtraQueryFilters }: FilterProps) => {
  const { t } = useTranslation();

  const getInitialValues = () => ({
    from: today,
    to: today,
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        from: Yup.number().required(t('required_field', { ns: 'errors' })),
        to: Yup.number().required(t('required_field', { ns: 'errors' })),
      }),
    );

  const onSubmit = (formValues: FormikValues) => {
    const newValues: any = { dateFrom: formValues.from, dateTo: formValues.to }
    const formikKeys = Object.keys(newValues);
    const queries: Array<QueryProps> = [];

    for (const v of formikKeys) {
      queries.push({ query: v, queryValue: newValues[v] });
    }
    setExtraQueryFilters(queries);
    setShowTable(true);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const handleChange = (prop: string) => (e: any) => {
    // if (prop === 'activity') {
    //   setShowTable(false);
    //   setFieldValue('agenda', '');
    // }
    setFieldValue(prop, e.target.value);
  };

  return {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    placeholder_from: t('date_from'),
    placeholder_to: t('date_to'),
    handleChange,
    showTable,
    setShowTable,
  };
};
