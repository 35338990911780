import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosAuthenticationInstance } from '@/src/api/instances/authInstance';
import { exportFromJson, exportFromBase64 } from '@/src/utilities/helpers/xlsxExportHelper';
import { lowerFirstLetter } from '@/src/utilities/helpers/stringsHelper';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';

const useExportData = (
  resource: string,
  columns: Array<any>,
  pathApi: string,
  extraFilters?: Array<QueryProps>,
  customApi = false,
) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const addExtraQueryFilters = () => {
    let extraQueryFilters = '';
    if (extraFilters?.length === 2 || extraFilters?.length === 1) {
      extraFilters?.forEach((element: QueryProps) => {
        extraQueryFilters += `&${element?.query}=${element?.queryValue}`;
      });
    }
    return extraQueryFilters;
  };

  const customApiExport = async () => {
    const response = await axiosAuthenticationInstance.get(
      `${pathApi}?page=0&pageSize=10000&searchText=${addExtraQueryFilters()}`,
    );
    exportFromBase64(response.data.report.content, lowerFirstLetter(t(`${resource}`)));
  };

  const commonExport = async () => {
    const response = await axiosAuthenticationInstance.get(
      `${pathApi}?page=0&pageSize=10000&searchText=${addExtraQueryFilters()}`,
    );

    const columnsToShow = columns
      .filter((x) => !x.omitExport)
      .map((column) => ({
        id: column.id,
        name: column.name,
        selector: column.selector,
      }));

    const formattedData = response.data[resource].map((x: any) => {
      const row: any = {};
      columnsToShow.forEach(({ name, selector }: { name: string; selector: any }) => {
        row[name as keyof typeof row] = selector(x);
      });
      return row;
    });
    exportFromJson(
      formattedData,
      columnsToShow.map((x) => x.name),
      lowerFirstLetter(t(`${resource}`)),
    );
  };

  const handleExportData = async () => {
    const loadData = async () => {
      setLoading(true);
      try {
        if (customApi) await customApiExport();
        else await commonExport();
      } catch (e: any) {
        throw new Error(e);
      } finally {
        setLoading(false);
      }
    };
    await loadData();
  };
  return { handleExportData, loading };
};

export default useExportData;
