import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';
import Filters from './filters/Filters';
import { FilterProps } from './filters/types';

export const ReportsPage = () => {
  const [showTable, setShowTable] = useState(false);
  const [extraQueryFilters, setExtraQueryFilters] = useState<Array<QueryProps>>([{ query: '', queryValue: '' }]);
  const { t } = useTranslation();
  const { columns, actionColumns } = useColumns();

  const filterProps: FilterProps = {
    showTable,
    setShowTable,
    setExtraQueryFilters,
  };

  return (
    <>
      <Filters {...filterProps} />
      {showTable && (
        <CustomDataTable
          title={t('package_reports_table_title', { ns: 'packages' })}
          columns={columns}
          customPageSize={20}
          extraFilters={extraQueryFilters}
          resource="reports"
          pathApi="packaging/v1/packages/reports"
          listName="reports"
          canSearch
          canExport
          exportApi="packaging/v1/packages/reports/export"
          defaultSortFieldId="unit"
          defaultSortAsc
          customActionColumns={actionColumns}
        />
      )}
    </>
  );
};
